import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { repos, allCountries } from './../repos';
import { AutocompileService } from './../autocompile.service';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table/table-data-source';
import { Observable } from 'rxjs/internal/Observable';
import { MatSort } from '@angular/material/sort';



@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

export class FormComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  constructor(private autocompileService:AutocompileService){
  }
  displayedColumns=['istat','comune','cap','regione','provincia','prefisso','superficie'];
  displayedColumns1=['name','iso2','iso3','unicode','dial','currency','capital','continent'];
  cap:string;
  continent:string;
  repos:repos[];
  capitals:string[];
  currency:string[];
  allCountries:allCountries[];
  iso2:string[];
  iso3:string[];
  nameNation:string[];
  prefix:string[];
  errorMessage;
  comuni;
  selectedCap:string;
  selectedComune:string;
  selectedRegione: string;
  selectedProvincia: string;
  prefisso: string;
  superficie: number;
  loading:boolean=false;
  searchString: string;
  filteredCountries:allCountries[];
  searchPrefix:string;
  searchNation:string;
  searchCapital:string;
  x:number=0;
  selectedTown:string;
  selectedIstat:string;
  provincia:string;
  log(x){console.log(x);}
  submit(f){console.log(f);}

  /*applyNameFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(!filterValue){
      this.filteredCountries=this.allCountries;
    }
    else{
      console.log("Filtering for " + filterValue);
      this.filteredCountries=this.allCountries.filter( obj => obj.name.toLowerCase().includes(filterValue.trim().toLowerCase()));
    }
    console.log(this.filteredCountries)
  }

  applyCapitalFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    if(!filterValue){
      this.filteredCountries=this.allCountries;
    }
    else{
      console.log("Filtering for " + filterValue);
      this.filteredCountries=this.allCountries.filter( obj => obj.capital.toLowerCase().includes(filterValue.trim().toLowerCase()));
    }
    console.log(this.filteredCountries)
  }

  applyDialFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    if(!filterValue){
      this.filteredCountries=this.allCountries;
    }
    else{
      console.log("Filtering for " + filterValue);
      this.filteredCountries=this.allCountries.filter( obj => obj.dial.toLowerCase().includes(filterValue.trim().toLowerCase()));
    }
    console.log(this.filteredCountries)
  }*/
  applyAllFilters(){
    this.filteredCountries=this.allCountries;
    
    if (this.searchNation!=null) {
      const filterValue=(this.searchNation);
      console.log("Filtering for " + filterValue);
      this.filteredCountries=this.filteredCountries.filter( obj => obj.name.toLowerCase().includes(filterValue.toLowerCase()));
    }
    if (this.searchCapital!=null) {
      const filterValue=(this.searchCapital);
      this.filteredCountries=this.filteredCountries.filter( obj => obj.capital.toLowerCase().includes(filterValue.toLowerCase()));
    }
    if (this.searchPrefix!=null) {
      const filterValue=(this.searchPrefix);
      this.filteredCountries=this.filteredCountries.filter( obj => obj.dial.toLowerCase().includes(filterValue.toLowerCase()));
    }
    if (this.continent!=null) {
      if(this.continent=="ALL"){
        this.filteredCountries=this.filteredCountries;
      }else{
      const filterValue=(this.continent);
      this.filteredCountries=this.filteredCountries.filter( obj => obj.continent.toLowerCase().includes(filterValue.toLowerCase()));
    }}
  }

  reset(){
    this.continent=null;
    this.searchCapital=null;
    this.searchNation=null;
    this.searchPrefix=null;
    this.filteredCountries=this.allCountries;
  }

  getComuni(){
    this.cap=this.selectedCap;

    this.loading=true;
    this.errorMessage='';
    this.autocompileService.getComuni(this.cap).subscribe((response)=>{
    console.log('response received')
    this.repos=response;
    this.selectedRegione = (this.repos[0])?.regione;
    this.selectedProvincia = (this.repos[0])?.provincia;
    this.prefisso = undefined;
    this.superficie = undefined;
  },
  (error)=>{ 
    console.error('request failed')
    this.errorMessage=error;
    this.loading=false;
    
  },
  ()=>{
    console.error('request completed')
    this.loading=false;
  }
  )}
  getCaps(){
    this.autocompileService.getCaps().subscribe((response)=>{
      console.log("response received")
      this.repos=response;
    })
  }
  getAllNation(){
    if(this.x==0){
    this.autocompileService.getAllNation().subscribe((response)=>{
      console.log("response received");
      this.allCountries=response;
      this.filteredCountries=this.allCountries;
      this.x++;
    })}
  }
  getAllCapitals(){
    this.autocompileService.getAllCapitals().subscribe((response)=>{
      console.log("response received")
      this.capitals=response;
    })
  }
  getContinents(){
    this.autocompileService.getContinent(this.continent).subscribe((Response)=>{
      console.log("response received");
      this.filteredCountries=Response;
    })
  }
  getCurrency(){
    this.autocompileService.getCurrency().subscribe((Response)=>{
      console.log("response received")
      this.currency=Response;
    })
  }
  getIso2(){
 this.autocompileService.getIso2().subscribe((Response)=>{
    console.log("response received")
    this.iso2=Response;
  })
  }
  getIso3(){
    this.autocompileService.getIso2().subscribe((Response)=>{
        console.log("response received")
        this.iso3=Response;
      })
  }
  getNameNation(){
    this.autocompileService.getIso2().subscribe((Response)=>{
        console.log("response received")
        this.nameNation=Response;
      })
  }
  getPrefix(){
    this.autocompileService.getIso2().subscribe((Response)=>{
        console.log("response received")
        this.prefix=Response;
      })
  }

  getProvince() {
    this.autocompileService.getProvince().subscribe((Response) => {
      console.log("response received")
      this.prefix = Response;
    })
  }
  getProvinceRequested() {
    this.autocompileService.getProvinceRequested(this.provincia).subscribe((Response) => {
      console.log("response received")
      this.repos = Response;
    })
  }
  getTown() {
    this.autocompileService.getTown(this.selectedTown).subscribe((Response) => {
      console.log("response received")
      this.repos = Response;
    })
  }
  getIstat() {
    this.autocompileService.getIstat(this.selectedIstat).subscribe((Response) => {
      console.log("response received")
      this.repos = Response;
    })
  }
  comuneScelto() {
    this.prefisso = this.repos.find(r => r.comune === this.selectedComune).prefisso;
    this.superficie = this.repos.find(r => r.comune === this.selectedComune).superficie;
  }
  ngOnInit() {

  }
  
  onSubmit(form: any): void {
    console.log('you submitted value:', form);
  }
}

<mat-tab-group (selectedTabChange)="getAllNation()">
    <mat-tab>
        <ng-template mat-tab-label>Cap Table</ng-template>
        <div class="example-container mat-elevation-z8">
            <mat-form-field appearance="fill">
                <mat-label for="cap">cap</mat-label>
                <input matInput type="text" minlength="5" maxlength="5" [(ngModel)]="selectedCap"
                    (focusout)="getComuni()">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label for="searchTown">Comune</mat-label>
                <input matInput type="text"  [(ngModel)]="selectedTown"
                (focusout)="getTown()">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label for="searchProv">Provincia</mat-label>
                <input matInput type="text"  [(ngModel)]="provincia"
                (focusout)="getProvinceRequested()">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label for="searchIstat">Istat</mat-label>
                <input matInput type="text"  [(ngModel)]="selectedIstat"
                (focusout)="getIstat()">
            </mat-form-field>
            <button mat-raised-button color="warn" (click)="getCaps()">ALL</button>
            <table mat-table [dataSource]="repos">

                <ng-container matColumnDef="istat">
                    <th mat-header-cell *matHeaderCellDef>istat</th>
                    <td mat-cell *matCellDef="let repos">{{repos.istat}}</td>
                </ng-container>

                <ng-container matColumnDef="comune">
                    <th mat-header-cell *matHeaderCellDef>comune</th>
                    <td mat-cell *matCellDef="let repos">{{repos.comune}}</td>
                </ng-container>

                <ng-container matColumnDef="cap">
                    <th mat-header-cell *matHeaderCellDef>cap</th>
                    <td mat-cell *matCellDef="let repos">{{repos.cap}}</td>
                </ng-container>

                <ng-container matColumnDef="regione">
                    <th mat-header-cell *matHeaderCellDef>regione</th>
                    <td mat-cell *matCellDef="let repos">{{repos.regione}}</td>
                </ng-container>

                <ng-container matColumnDef="provincia">
                    <th mat-header-cell *matHeaderCellDef>provincia</th>
                    <td mat-cell *matCellDef="let repos">{{repos.provincia}}</td>
                </ng-container>

                <ng-container matColumnDef="prefisso">
                    <th mat-header-cell *matHeaderCellDef>prefisso</th>
                    <td mat-cell *matCellDef="let repos">{{repos.prefisso}}</td>
                </ng-container>

                <ng-container matColumnDef="superficie">
                    <th mat-header-cell *matHeaderCellDef>superficie</th>
                    <td mat-cell *matCellDef="let repos">{{repos.superficie}} km²</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns:displayedColumns"></tr>
            </table>
        </div>
    </mat-tab>

    <mat-tab id="nation">
        <ng-template mat-tab-label>Nations Table</ng-template>
        <div class="example-container mat-elevation-z8">

            <mat-form-field appearance="fill">
                <mat-label for="ricerca">Ricerca Nazione</mat-label>
                <input matInput type="text" [(ngModel)]="searchNation" placeholder="Type to search..." #input />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label for="ricerca">Ricerca Capitale</mat-label>
                <input matInput type="text" [(ngModel)]="searchCapital" placeholder="Type to search..." #input />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label for="ricerca">Ricerca Prefisso</mat-label>
                <input matInput type="text" [(ngModel)]="searchPrefix" placeholder="Type to search..." #input />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Scegli il continente</mat-label>
                <mat-select [(ngModel)]="continent">
                    <mat-option value="AS">Asia</mat-option>
                    <mat-option value="AF">Africa</mat-option>
                    <mat-option value="SA">Sud America</mat-option>
                    <mat-option value="NA">Nord America</mat-option>
                    <mat-option value="OC">Oceania</mat-option>
                    <mat-option value="EU">Europa</mat-option>
                    <mat-option value="ALL">Tutte le Nazioni</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button color="primary" (click)="applyAllFilters()">SEARCH</button>
            <button mat-raised-button color="warn" (click)="reset()">RESET</button>

            <table mat-table [dataSource]="filteredCountries">

                <ng-container matColumnDef="name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Nazione</th>
                    <td mat-cell *matCellDef="let allCountries">{{allCountries.name}}</td>
                </ng-container>

                <ng-container matColumnDef="iso2">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>iso 2</th>
                    <td mat-cell *matCellDef="let allCountries">{{allCountries.iso2}}</td>
                </ng-container>

                <ng-container matColumnDef="iso3">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>iso 3</th>
                    <td mat-cell *matCellDef="let allCountries">{{allCountries.iso3}}</td>
                </ng-container>

                <ng-container matColumnDef="unicode">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>unicode</th>
                    <td mat-cell *matCellDef="let allCountries">{{allCountries.unicode}}</td>
                </ng-container>

                <ng-container matColumnDef="capital">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>capitale</th>
                    <td mat-cell *matCellDef="let allCountries">{{allCountries.capital}}</td>
                </ng-container>

                <ng-container matColumnDef="currency">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>valuta</th>
                    <td mat-cell *matCellDef="let allCountries">{{allCountries.currency}}</td>
                </ng-container>

                <ng-container matColumnDef="dial">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>dial</th>
                    <td mat-cell *matCellDef="let allCountries">{{allCountries.dial}}</td>
                </ng-container>

                <ng-container matColumnDef="continent">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>continente</th>
                    <td mat-cell *matCellDef="let allCountries">{{allCountries.continent}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns:displayedColumns1"></tr>
            </table>
        </div>
    </mat-tab>
</mat-tab-group>
import { repos, allCountries } from './repos';
import { getTestBed } from '@angular/core/testing';
import { FormGroup, Form } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Component } from '@angular/core';
import { Observable, ObservedValueOf, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { FormComponent } from './form/form.component';



@Injectable({ providedIn:'root' })
export class AutocompileService {
  private baseUrl='https://testapi-autocompile.soluzione1.it/autocompile/rest'
  constructor(private http:HttpClient) { }

  getComuni(cap:string): Observable<repos[]> {
    return this.http.get<repos[]>(`${this.baseUrl}${"/form?cap="}${cap}`)
  }
  getCaps():Observable<repos[]>{
    return this.http.get<repos[]>(`${this.baseUrl}${"/caps"}`)
  }

  getAllNation():Observable<allCountries[]>{
    return this.http.get<allCountries[]>(`${this.baseUrl}${"/nation"}`)
  }

  getAllCapitals():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/Capital"}`)
  }
  getContinent(continent:string):Observable<allCountries[]>{
    return this.http.get<allCountries[]>(`${this.baseUrl}${"/continent?Continent="}${continent}`)
  }
  getCurrency():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/currency"}`)
  }
  getIso2():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/Iso2"}`)
  }
  getIso3():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/Iso3"}`)
  }
  getNameNation():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/NationName"}`)
  }
  getPrefix():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/Prefix"}`)
  }
  getProvince():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/form/province"}`)
  }
  getProvinceRequested(provincia:string):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/provSearch?prov="}${provincia}`)
  }
  getTown(town:string):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/town?town="}${town}`)
  }
  getIstat(istat:string):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}${"/istat?istat="}${istat}`)
  }
}
